import './App.css';
import AccountWall from './AccountWall';

function App() {
    return (
        <AccountWall />
    );
}

export default App;
