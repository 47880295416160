import React, { useState, useEffect } from 'react';
import GoogleButton from 'react-google-button';

import API from './API';

function Login() {
    return (
        <div className="container">
            <GoogleButton
                onClick={() => {
                    API.login();
                }}
            />
        </div>
    );
}

export default Login;
