import React, { useRef, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from "react-router-dom";

import { Remark } from 'react-remark';
import remarkBreaks from 'remark-breaks';

import "react-chat-elements/dist/main.css";
import {
    MessageList,
    MessageBox,
    Input,
    Button,
} from "react-chat-elements";

import API from './API';

var md5 = require('md5');



let clearInput = () => {};

function Chat() {
    const ref = useRef();

    const [ cookies, setCookie ] = useCookies([ 'uid', 'token' ]);
    const [ messages, setMessages ] = useState([]);
    const [ lastMessageTs, setLastMessageTs ] = useState(0);

    const search = useLocation().search;
    const country = new URLSearchParams( search ).get('country');
    console.log( "country:", country );

    const addMessage = function( message ) {
        setMessages( prevMessages => {
            const index = prevMessages.findIndex( m => m.key === message.key );
            if ( index !== -1 ) {
                prevMessages[ index ] = message;

            } else {
                prevMessages.push( message );
            }
            return prevMessages;
        });

        setLastMessageTs( new Date().getTime() );
    }

    useEffect(() => {
        console.log( "INIT Pusher")
        const pusher = API.getPusherInstance( cookies.token );
        const channelName = 'private-channel-' + cookies.uid;
        const channel = pusher.subscribe( channelName );
        channel.bind('client-refresh', function ( message ) {
            console.log( "message", message );
            if ( message.message != null && message.message.trim() != "" ) {
                addMessage({
                    key: ( message.message_id != null ) ? message.message_id : md5( JSON.stringify( message ) ),
                    position: "left",
                    title: "AskRobot",
                    type: "text",
                    markdown: true,
                    text: message.message.trim(),
                    date: new Date(),
                });
            }
        });
    }, []);

    const propsRemark = {
        remarkPlugins: [ remarkBreaks ],
    };

    const propsRemarkMarkdown = {
        remarkPlugins: [ remarkBreaks ],
        rehypeReactOptions: {
            components: {
                em: (props) => <strong {...props} />,
            },
        },
    }

    return (
        <div className="chat-container">
            <div className="message-block">
                <MessageList
                    className='message-list'
                    lockable={true}
                    toBottomHeight={'100%'}
                    dataSource={messages.map( m => {
                        return {
                            position: m.position,
                            title: ( m.title != null ) ? m.title : null,
                            type: m.type,
                            text: ( m.markdown != null && m.markdown === true )
                                    ? <Remark {...propsRemarkMarkdown}>{m.text}</Remark>
                                    : <Remark {...propsRemark}>{m.text}</Remark>,
                            date: m.date,
                        };
                    })}
                />
            </div>

            <div className="input-block">
                <Input
                    referance={ref}
                    clear={( clear ) => {
                        console.log( "!!!! SET CLEAR" )
                        clearInput = clear;
                    }}
                    placeholder="Type here..."
                    multiline={true}
                    rightButtons={
                        <Button
                            text={"Send"}
                            onClick={() => {
                                if (
                                    ref != null
                                    && ref.current != null
                                    && ref.current.value != null
                                    && ref.current.value.trim().length > 0
                                ) {
                                    addMessage({
                                        key: md5( JSON.stringify({
                                            'text': ref.current.value,
                                            'ts': new Date().getTime(),
                                        })),
                                        position: "right",
                                        title: "Me",
                                        type: "text",
                                        text: ref.current.value.trim(),
                                        date: new Date(),
                                    });

                                    API.sendChat( cookies.token, {
                                        'country': country,
                                        'question': ref.current.value,
                                    });

                                    ref.current.value = '';
                                    clearInput();
                                }
                            }}
                            title="Send"
                        />
                    }
                />
            </div>
        </div>
    );
}

export default Chat;
