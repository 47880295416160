import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';

function Callback() {
    const navigate = useNavigate();
    const [ cookies, setCookie ] = useCookies([ 'uid', 'token' ]);

    useEffect(() => {
        let query = {}
        for ( let name of ['uid', 'token'] ) {
            let value = new URLSearchParams( window.location.search ).get( name );
            if ( value != null ) {
                query[ name ] = value;
                setCookie( name, value, {
                    path: "/",
                    expires: new Date( new Date().getTime() + 30 * 86400 * 1000 ),
                })
            }
        }

        navigate('/');
        
    }, []);
    return <></>;
}

export default Callback;
