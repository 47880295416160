import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

import API from './API';
import Login from './Login';
import Chat from './Chat';


function AccountWall() {
    const [ cookies, setCookie ] = useCookies([ 'uid', 'token' ]);
    const [ tokenData, setTokenData ] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setTokenData( await API.getTokenData( cookies.token ) );
        };

        if ( cookies.token != null ) {
            fetchData();

        } else {
            setTokenData(false);
        }

        return () => {};

    }, [ cookies.token ]);

    if ( tokenData === null ) {
        return <></>;
    }

    if ( tokenData === false ) {
        return <Login />;
    }

    return <Chat />;
}

export default AccountWall;
